import { Modal } from "react-bootstrap";
import { SubMode } from "../types/Enums";

export function Explanations(props: {
  showExplanations: boolean;
  gameMode: SubMode;
  hideExplanations: () => void;
}) {
  const getBody = () => {
    switch (props.gameMode) {
      case SubMode.PosterMovieGuesser:
        return (
          <>
            Ici, votre mission consiste à découvrir le titre du film en vous
            fiant uniquement à son affiche.
            <br />
            <br />
            Oubliez la saisie de mots, car dans ce mode, vous devez entrer
            l'intégralité du titre du film d'un seul coup.
            <br /> Et devinez quoi ? Aucune préoccupation pour les majuscules,
            le genre, le nombre ou les ponctuations !
            <br /> Soyez libre de simplement taper le titre tel que vous le
            pensez. Mais attention à l'orthographe !
            <br /> <br />
            Pour ajouter un peu de piquant à l'aventure, l'affichage du film est
            délibérément obscurci pour augmenter la difficulté.
            <br />
            Chaque fois que vous faites une erreur, l'image devient
            progressivement plus nette, mais attention, il n'y a que 10 niveaux
            de détail ! Une fois que vous atteignez ce seuil, l'image reste
            inchangée, peu importe le nombre de tentatives.
            <br />
            <br />
            Faites preuve d'observation, soyez précis dans votre choix de titre
            et relevez le défi.
            <br />
            Chaque jour, un nouveau film est sélectionné au hasard.
            <br />
            <br />
            Bonne chance, et que la magie du cinéma vous guide vers la victoire
            !
          </>
        );
      case SubMode.BookGuesser:
        return (
          <>
            L'objectif est simple : trouvez le titre d'un livre en dévoilant
            progressivement les mots de ses genres, son année de sa première
            parution et de son synopsis.
            <br />
            Afin de vous aider, des indices sont également disponibles en vous
            fournissant les informations sur le siècle durant lequel le livre
            est sortie pour la première fois, la langue originale ainsi que les
            auteur(e)s !
            <br />
            <br />
            Les mots dans les différentes sections seront affichés en vert une
            fois découvert, puis resteront en noir. Les mots similaires dans le
            contexte seront indiqués en orange pour le mot actuel, puis seront
            grisés. La couleur varie en fonction de la proximité de votre
            réponse.
            <br />
            Attention, cette fonction ne s'applique pas au titre !
            <br />
            <br />
            Dès que vous identifiez le titre du livre, vous gagnez !
            <br /> Pas besoin de majuscules, mais l'orthographe compte. Les mots
            sont lemmatisés, donc la conjugaison, le genre et le nombre n'ont
            pas d'importance.
            <br />
            <br />
            À la fin, un bouton vous permettra de voir toutes les informations
            de l'ouvrage. Vous pouvez aussi continuer à jouer sans affecter
            votre score de victoire !
            <br />
            <br />
            Chaque jour, un nouveau livre est sélectionné au hasard.
            <br />
            <br /> Amusez-vous bien !
          </>
        );
        case SubMode.LanguageGuesser:
          return (
            <>
              L'objectif est simple : trouvez le nom de la langue du texte affiché
              <br />
              La phrase affichée est toujours la même, une citation de Nelson Mandela, traduite dans les différentes langues :
              <br /><br/>
              <b>"Sans langue, il nous est impossible de parler aux gens et de les comprendre ; on ne peut pas partager leurs espoirs et leurs aspirations, saisir leur histoire, apprécier leur poésie ou savourer leurs chansons. ~ Nelson Mandela, Long Walk to Freedom"</b>
              <br/><br/>
              Afin de vous aider, des indices sont également disponibles en vous
              fournissant les informations sur le code ISO de la langue ou encore les régions où elle est la plus utilisée !
              <br />
              <br />
              Ici, pas de mots à taper, il faut directement trouver le nom de la langue parmis la liste des disponibles !!
              <br />
              <br />
              Dès que vous identifiez la langue, vous gagnez !
              <br />
              <br />
              Chaque jour, une nouvelle langue est sélectionné au hasard.
              <br />
              <br /> Amusez-vous bien !
            </>
          );
      case SubMode.EmojiBookGuesser:
        return (
          <>
            L'objectif est simple : trouvez le titre d'un livre à l'aide d'une
            suite d'émojis !
            <br />
            Afin de vous aider, des indices sont également disponibles en vous
            fournissant les informations sur livre !
            <br />
            <br />
            Ici, pas de mots à taper, il faut directement trouver le livre
            parmis la liste des titres disponibles !!
            <br />
            <br />
            Dès que vous identifiez le titre du livre, vous gagnez !
            <br />
            <br />
            À la fin, un bouton vous permettra de voir toutes les informations
            de l'ouvrage.
            <br />
            <br />
            Chaque jour, un nouveau livre est sélectionné au hasard.
            <br />
            <br /> Amusez-vous bien !
          </>
        );
        case SubMode.EmojiMovieGuesser:
          return (
            <>
              L'objectif est simple : trouvez le titre d'un film à l'aide d'une
              suite d'émojis !
              <br />
              Afin de vous aider, des indices sont également disponibles en vous
              fournissant les informations sur film !
              <br />
              <br />
              Ici, pas de mots à taper, il faut directement trouver le film
              parmis la liste des titres disponibles !!
              <br />
              <br />
              Dès que vous identifiez le titre, vous gagnez !
              <br />
              <br />
              À la fin, un bouton vous permettra de voir toutes les informations
              du film.
              <br />
              <br />
              Chaque jour, un nouveau film est sélectionné au hasard.
              <br />
              <br /> Amusez-vous bien !
            </>
          );
      default:
        return (
          <>
            L'objectif est simple : trouvez le titre d'un film en dévoilant
            progressivement les mots de ses genres, sa date de sortie, sa durée
            et de son synopsis.
            <br />
            <br />
            Les mots dans les différentes sections seront affichés en vert une
            fois découvert, puis resteront en noir. Les mots similaires dans le
            contexte seront indiqués en orange pour le mot actuel, puis seront
            grisés. La couleur varie en fonction de la proximité de votre
            réponse.
            <br />
            Attention, cette fonction ne s'applique pas au titre !
            <br />
            <br />
            Dès que vous identifiez le titre du film, vous gagnez !
            <br /> Pas besoin de majuscules, mais l'orthographe compte. Les mots
            sont lemmatisés, donc la conjugaison, le genre et le nombre n'ont
            pas d'importance.
            <br />
            <br />
            À la fin, un bouton vous permettra de voir le synopsis complet,
            l'affiche du film et les plateformes de streaming où il est
            disponible (comme Netflix, Amazon Prime, etc.). Vous pouvez aussi
            continuer à jouer sans affecter votre score de victoire !
            <br />
            <br />
            Chaque jour, un nouveau film est sélectionné au hasard.
            <br />
            <br /> Amusez-vous bien !
          </>
        );
    }
  };

  return (
    <>
      <Modal
        className="gameModal"
        show={props.showExplanations}
        onHide={props.hideExplanations}
      >
        <Modal.Header closeButton>
          <Modal.Title id="explanationsTitle">🔎 Filmantix 🔍</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getBody()}</Modal.Body>
      </Modal>
    </>
  );
}
