export const getDayOfYear = (): number => {
  // Get the current date
  const currentDate = new Date();

  // Get the date of the first day of the current year
  const startOfYear = new Date(currentDate.getFullYear(), 0, 0);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = currentDate.getTime() - startOfYear.getTime();

  // Convert the difference to days and round up
  const dayOfYear = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return dayOfYear;
};
