import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { ApiRequest, EmojiMovieType } from "../../types/Types";
import "../../App.css";
import { Summary } from "../Summary";
import { titlesToSummaryRow } from "../utils/Utils";
import { ResultWithImage } from "../ResultWithImage";

export function EmojiMovie(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [titles, setTitles] = useState<string[]>([]);
  const [yesterday, setYesterday] = useState<EmojiMovieType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [win, setWin] = useState(false);
  const [isError, setIsError] = useState(false);
  const [today, setToday] = useState<EmojiMovieType | null>(null);
  const [resultNbGuess, setResultNbGuess] = useState<number>(0);
  const [inputWord, setInputWord] = useState<Option[]>([]);
  const [guessedTitles, setGuessedTitles] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showRuntime, setShowRuntime] = useState<boolean>(false);
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [showGenres, setShowGenres] = useState<boolean>(false);
  const [showCompanies, setShowCompanies] = useState<boolean>(false);
  const [showDate, setShowDate] = useState<boolean>(false);

  const revealData = (
    value: boolean,
    setValue: (x: boolean) => void,
    storageKey: string
  ) => {
    if (value) {
      return;
    }

    setValue(true);
    let filmantix = window.localStorage.getItem("emojiMovieGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData[storageKey] = true;
      localStorage.setItem("emojiMovieGuesser", JSON.stringify(filmantixData));
    }
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiMovie/today",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & EmojiMovieType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setToday({
            emojis: data.emojis,
            date: data.date,
            genres: data.genres,
            runtime: data.runtime,
            countries: data.countries,
            companies: data.companies,
            image: "",
          });
          let filmantix = window.localStorage.getItem("emojiMovieGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const emojiMovieDate = new Date(data.today);

            const today = new Date(filmantixData.date);

            if (
              filmantixData?.date &&
              emojiMovieDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.revealRuntime) {
                revealData(showRuntime, setShowRuntime, "revealRuntime");
              }
              if (filmantixData.revealCountries) {
                revealData(showCountries, setShowCountries, "revealCountries");
              }
              if (filmantixData.revealCompanies) {
                revealData(showCompanies, setShowCompanies, "revealCompanies");
              }
              if (filmantixData.revealGenres) {
                revealData(showGenres, setShowGenres, "revealGenres");
              }
              if (filmantixData.revealDate) {
                revealData(showDate, setShowDate, "revealDate");
              }

              if (filmantixData.win) {
                setWin(true);
                setToday(filmantixData.today);
                setResultNbGuess(filmantixData.counter);
              }

              if (filmantixData.guessedTitles) {
                setGuessedTitles(filmantixData.guessedTitles);
              }
            } else {
              const newfilmantixData = {
                date: data.today,
                guessedTitles: [],
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem(
                "emojiMovieGuesser",
                JSON.stringify(newfilmantixData)
              );
              setGuessedTitles([]);
            }
          } else {
            const filmantixData = {
              date: data.today,
              guessedTitles: [],
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem(
              "emojiMovieGuesser",
              JSON.stringify(filmantixData)
            );
            setGuessedTitles([]);
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiMovie/titles",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & { titles: string[] }) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setTitles(data.titles);
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiMovie/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & EmojiMovieType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setYesterday(null);
        } else {
          setYesterday({
            emojis: data.emojis,
            image: data.image,
            title: data.title,
            date: data.date,
            overview: data.overview,
            genres: data.genres,
            runtime: data.runtime,
            countries: data.countries,
            companies: data.companies,
            flatrates: data.flatrates,
          });
        }
      });
  }, [
    props,
    loaded,
    showRuntime,
    showCountries,
    showCompanies,
    showGenres,
    showDate,
  ]);

  const sendTitle = (title: string) => {
    if (!title) {
      return;
    }
    setInputWord([]);

    if (!guessedTitles.includes(title)) {
      if (!win) {
        setGuessedTitles((prev) => {
          const data = [title, ...prev];

          let filmantix = window.localStorage.getItem("emojiMovieGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);

            filmantixData.guessedTitles = data;
            localStorage.setItem(
              "emojiMovieGuesser",
              JSON.stringify(filmantixData)
            );
          }
          return data;
        });

        const apiUrl = process.env.REACT_APP_API_PATH + "/api/emojiMovie/guess";

        // Configuration de la requête
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
          },
          body: JSON.stringify({ title }), // Convertir le tableau en JSON
        };

        fetch(apiUrl, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data: ApiRequest & EmojiMovieType & { counter: number }) => {
            setIsLoading(false);
            if (data.status === "ok") {
              let filmantix = window.localStorage.getItem("emojiMovieGuesser");
              if (filmantix) {
                let filmantixData = JSON.parse(filmantix);
                const emojiMovieDate = new Date(data.today);
                const today = new Date(filmantixData.today);

                if (
                  filmantixData?.date &&
                  emojiMovieDate.getTime() > today.getTime()
                ) {
                  setLoaded(false);
                } else {
                  const todayData = {
                    emojis: data.emojis,
                    image: data.image,
                    title: data.title,
                    date: data.date,
                    overview: data.overview,
                    genres: data.genres,
                    runtime: data.runtime,
                    countries: data.countries,
                    companies: data.companies,
                    flatrates: data.flatrates,
                  };
                  setToday(todayData);
                  setResultNbGuess(data.counter);
                  setWin(true);

                  filmantixData.today = todayData;
                  filmantixData.win = true;
                  filmantixData.counter = data.counter;
                  localStorage.setItem(
                    "emojiMovieGuesser",
                    JSON.stringify(filmantixData)
                  );
                }
              }
            }
          });
      }
    }
  };

  const getRevealDiv = (
    title: string,
    show: boolean,
    value: any,
    setValue: (x: boolean) => void,
    storageKey: string
  ) => {
    return (
      <div className="help">
        <b>{title}</b> :{" "}
        {show ? (
          value
        ) : (
          <Button
            className="resultButton helpButton "
            variant="secondary"
            onClick={() => revealData(show, setValue, storageKey)}
          >
            Révéler
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isLoading && <p>Chargement...</p>}
        {isError && <p>Une erreur est survenue !</p>}
        {!isLoading && !isError && (
          <div id="filmantix" className="game emoji">
            {win && (
              <label className="error" style={{ marginBottom: "2%" }}>
                Bravo !! Vous avez trouvé en <i>{guessedTitles.length}</i>{" "}
                tentatives ! Vous êtes la{" "}
                <b>
                  {resultNbGuess}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir film
                </Button>
              </label>
            )}
            {today && (
              <ResultWithImage<EmojiMovieType>
                showResult={showResults}
                hideResult={() => setShowResults(false)}
                data={today}
                nbOfWords={guessedTitles.length}
              />
            )}
            <div
              style={{
                width: "95%",
                marginBottom: "2%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {getRevealDiv(
                "Date",
                showDate,
                today?.date,
                setShowDate,
                "revealDate"
              )}
              {getRevealDiv(
                "Durée",
                showRuntime,
                today?.runtime + "min",
                setShowRuntime,
                "revealRuntime"
              )}
              {getRevealDiv(
                "Pays de production",
                showCountries,
                today?.countries.join(" -- "),
                setShowCountries,
                "revealCountries"
              )}
              {getRevealDiv(
                "Compagnies de production",
                showCompanies,
                today?.companies.join(" -- "),
                setShowCompanies,
                "revealCompanies"
              )}
              {getRevealDiv(
                "Genres",
                showGenres,
                today?.genres.join(" -- "),
                setShowGenres,
                "revealGenres"
              )}
            </div>
            <Form.Group className="emojiForm">
              <Typeahead
                id="basic-typeahead-single"
                className="emojiInput"
                labelKey="movieTitle"
                onChange={setInputWord}
                options={titles}
                placeholder={"Titre du film..."}
                selected={inputWord}
                disabled={win}
              />

              <button
                className="guessSubmit btn btn-secondary emojiButtonSend"
                disabled={win}
                onClick={() =>
                  sendTitle(
                    inputWord && inputWord.length > 0
                      ? (inputWord[0] as string)
                      : ""
                  )
                }
              >
                Envoyer
              </button>
            </Form.Group>
            <h1 className="emojiList emojiFont">{today?.emojis}</h1>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        {yesterday && yesterday.title && (
          <Summary
            displayGuessedData={() => titlesToSummaryRow(guessedTitles)}
            yesterdayTitle={[yesterday.title]}
            result={
              <ResultWithImage<EmojiMovieType>
                showResult={showYesterday}
                hideResult={() => setShowYesterday(false)}
                data={yesterday}
              />
            }
            showYesterday={() => setShowYesterday(true)}
            onInfoButtonPressed={() => props.setShowExplanations(true)}
          />
        )}
      </div>
    </>
  );
}
