export function Footer() {
  return (
    <p>
      Application réalisée par <a href="mailto:filmantix@gmail.com"><b>Victor Villemin</b></a>. Données provenant de{" "}
      <a
        href="https://developer.imdb.com/non-commercial-datasets/"
        rel="noreferrer"
        target="_blank"
      >
        IMDb Non-Commercial Datasets
      </a>
      ,{" "}
      <a href="https://www.justwatch.com/" rel="noreferrer" target="_blank">
        JustWatch
      </a>{" "},
      <a href="https://openlibrary.org/" rel="noreferrer" target="_blank">
        OpenLibrary
      </a>{" "}
      et{" "}
      <a href="https://www.themoviedb.org/" rel="noreferrer" target="_blank">
        TMDB
      </a>
      . Calcul de similarité basé sur les travaux de{" "}
      <a
        href="https://fauconnier.github.io/#data"
        rel="noreferrer"
        target="_blank"
      >
        Jean-Philippe Fauconnier
      </a>
    </p>
  );
}
