import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { ApiRequest, EmojiBookType } from "../../types/Types";
import "../../App.css";
import { Summary } from "../Summary";
import { titlesToSummaryRow } from "../utils/Utils";
import { ResultWithImage } from "../ResultWithImage";

export function EmojiBook(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [titles, setTitles] = useState<string[]>([]);
  const [yesterday, setYesterday] = useState<EmojiBookType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [win, setWin] = useState(false);
  const [isError, setIsError] = useState(false);
  const [today, setToday] = useState<EmojiBookType | null>(null);
  const [resultNbGuess, setResultNbGuess] = useState<number>(0);
  const [inputWord, setInputWord] = useState<Option[]>([]);
  const [guessedTitles, setGuessedTitles] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showCentury, setShowCentury] = useState<boolean>(false);
  const [showAuthors, setShowAuthors] = useState<boolean>(false);
  const [showGenres, setShowGenres] = useState<boolean>(false);
  const [showOriginalLanguage, setShowOriginalLanguage] =
    useState<boolean>(false);
  const [showDate, setShowDate] = useState<boolean>(false);

  const revealCentury = useCallback(() => {
    if (showCentury) {
      return;
    }

    setShowCentury(true);
    let filmantix = window.localStorage.getItem("emojiBookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealCentury = true;
      localStorage.setItem("emojiBookGuesser", JSON.stringify(filmantixData));
    }
  }, [showCentury]);

  const revealAuthors = useCallback(() => {
    if (showAuthors) {
      return;
    }

    setShowAuthors(true);
    let filmantix = window.localStorage.getItem("emojiBookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealAuthors = true;
      localStorage.setItem("emojiBookGuesser", JSON.stringify(filmantixData));
    }
  }, [showAuthors]);

  const revealGenres = useCallback(() => {
    if (showGenres) {
      return;
    }

    setShowGenres(true);
    let filmantix = window.localStorage.getItem("emojiBookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealGenres = true;
      localStorage.setItem("emojiBookGuesser", JSON.stringify(filmantixData));
    }
  }, [showGenres]);

  const revealOriginalLanguage = useCallback(() => {
    if (showOriginalLanguage) {
      return;
    }

    setShowOriginalLanguage(true);
    let filmantix = window.localStorage.getItem("emojiBookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealOriginalLanguage = true;
      localStorage.setItem("emojiBookGuesser", JSON.stringify(filmantixData));
    }
  }, [showOriginalLanguage]);

  const revealDate = useCallback(() => {
    if (showDate) {
      return;
    }

    setShowDate(true);
    let filmantix = window.localStorage.getItem("emojiBookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealDate = true;
      localStorage.setItem("emojiBookGuesser", JSON.stringify(filmantixData));
    }
  }, [showDate]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiBook/today",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & EmojiBookType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setToday({
            emojis: data.emojis,
            century: data.century,
            date: data.date,
            genres: data.genres,
            original_language: data.original_language,
            authors: data.authors,
            image: "",
          });
          let filmantix = window.localStorage.getItem("emojiBookGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const emojiBookDate = new Date(data.today);

            const today = new Date(filmantixData.date);

            if (
              filmantixData?.date &&
              emojiBookDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.revealCentury) {
                revealCentury();
              }
              if (filmantixData.revealAuthors) {
                revealAuthors();
              }
              if (filmantixData.revealOriginalLanguage) {
                revealOriginalLanguage();
              }
              if (filmantixData.revealGenres) {
                revealGenres();
              }
              if (filmantixData.revealDate) {
                revealDate();
              }

              if (filmantixData.win) {
                setWin(true);
                setToday(filmantixData.today);
                setResultNbGuess(filmantixData.counter);
              }

              if (filmantixData.guessedTitles) {
                setGuessedTitles(filmantixData.guessedTitles);
              }
            } else {
              const newfilmantixData = {
                date: data.today,
                guessedTitles: [],
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem(
                "emojiBookGuesser",
                JSON.stringify(newfilmantixData)
              );
              setGuessedTitles([]);
            }
          } else {
            const filmantixData = {
              date: data.today,
              guessedTitles: [],
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem(
              "emojiBookGuesser",
              JSON.stringify(filmantixData)
            );
            setGuessedTitles([]);
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiBook/titles",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & { titles: string[] }) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setTitles(data.titles);
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/emojiBook/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & EmojiBookType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setYesterday(null);
        } else {
          setYesterday({
            emojis: data.emojis,
            century: data.century,
            date: data.date,
            genres: data.genres,
            original_language: data.original_language,
            authors: data.authors,
            title: data.title,
            image: data.image,
            overview: data.overview,
          });
        }
      });
  }, [
    props,
    loaded,
    revealCentury,
    revealAuthors,
    revealOriginalLanguage,
    revealGenres,
    revealDate,
  ]);

  const sendTitle = (title: string) => {
    if (!title) {
      return;
    }
    setInputWord([]);

    if (!guessedTitles.includes(title)) {
      if (!win) {
        setGuessedTitles((prev) => {
          const data = [title, ...prev];

          let filmantix = window.localStorage.getItem("emojiBookGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);

            filmantixData.guessedTitles = data;
            localStorage.setItem(
              "emojiBookGuesser",
              JSON.stringify(filmantixData)
            );
          }
          return data;
        });

        const apiUrl = process.env.REACT_APP_API_PATH + "/api/emojiBook/guess";

        // Configuration de la requête
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
          },
          body: JSON.stringify({ title }), // Convertir le tableau en JSON
        };

        fetch(apiUrl, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data: ApiRequest & EmojiBookType & { counter: number }) => {
            setIsLoading(false);
            if (data.status === "ok") {
              let filmantix = window.localStorage.getItem("emojiBookGuesser");
              if (filmantix) {
                let filmantixData = JSON.parse(filmantix);
                const emojiBookDate = new Date(data.today);
                const today = new Date(filmantixData.today);

                if (
                  filmantixData?.date &&
                  emojiBookDate.getTime() > today.getTime()
                ) {
                  setLoaded(false);
                } else {
                  const todayData = {
                    emojis: data.emojis,
                    century: data.century,
                    date: data.date,
                    genres: data.genres,
                    original_language: data.original_language,
                    authors: data.authors,
                    title: data.title,
                    image: data.image,
                    overview: data.overview,
                  };
                  setToday(todayData);
                  setResultNbGuess(data.counter);
                  setWin(true);

                  filmantixData.today = todayData;
                  filmantixData.win = true;
                  filmantixData.counter = data.counter;
                  localStorage.setItem(
                    "emojiBookGuesser",
                    JSON.stringify(filmantixData)
                  );
                }
              }
            }
          });
      }
    }
  };

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isLoading && <p>Chargement...</p>}
        {isError && <p>Une erreur est survenue !</p>}
        {!isLoading && !isError && (
          <div id="filmantix" className="game emoji">
            {win && (
              <label className="error" style={{ marginBottom: "2%" }}>
                Bravo !! Vous avez trouvé en <i>{guessedTitles.length}</i>{" "}
                tentatives ! Vous êtes la{" "}
                <b>
                  {resultNbGuess}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir livre
                </Button>
              </label>
            )}
            {today && (
              <ResultWithImage<EmojiBookType>
                showResult={showResults}
                hideResult={() => setShowResults(false)}
                data={today}
                nbOfWords={guessedTitles.length}
              />
            )}
            <div
              style={{
                width: "95%",
                marginBottom: "2%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="help">
                <b>Siècle</b> :{" "}
                {showCentury ? (
                  today?.century + "ième"
                ) : (
                  <Button
                    className="resultButton helpButton  "
                    variant="secondary"
                    onClick={revealCentury}
                  >
                    Révéler
                  </Button>
                )}
              </div>
              <div className="help">
                <b>Date</b> :{" "}
                {showDate ? (
                  today?.date
                ) : (
                  <Button
                    className="resultButton helpButton "
                    variant="secondary"
                    onClick={revealDate}
                  >
                    Révéler
                  </Button>
                )}
              </div>
              <div className="help">
                <b>Langue originale</b> :{" "}
                {showOriginalLanguage ? (
                  today?.original_language
                ) : (
                  <Button
                    className="resultButton helpButton "
                    variant="secondary"
                    onClick={revealOriginalLanguage}
                  >
                    Révéler
                  </Button>
                )}
              </div>
              <div className="help">
                <b>Auteur(e)s</b> :{" "}
                {showAuthors ? (
                  today?.authors.join(" -- ")
                ) : (
                  <Button
                    className="resultButton helpButton "
                    variant="secondary"
                    onClick={revealAuthors}
                  >
                    Révéler
                  </Button>
                )}
              </div>
              <div className="help">
                <b>Genres</b> :{" "}
                {showGenres ? (
                  <>{today?.genres.join(" -- ")}</>
                ) : (
                  <Button
                    className="resultButton helpButton "
                    variant="secondary"
                    onClick={revealGenres}
                  >
                    Révéler
                  </Button>
                )}
              </div>
            </div>
            <Form.Group className="emojiForm">
              <Typeahead
                id="basic-typeahead-single"
                className="emojiInput"
                labelKey="bookTitle"
                onChange={setInputWord}
                options={titles}
                placeholder={"Titre du livre..."}
                selected={inputWord}
                disabled={win}
              />

              <button
                className="guessSubmit btn btn-secondary emojiButtonSend"
                disabled={win}
                onClick={() =>
                  sendTitle(
                    inputWord && inputWord.length > 0
                      ? (inputWord[0] as string)
                      : ""
                  )
                }
              >
                Envoyer
              </button>
            </Form.Group>
            <h1 className="emojiList emojiFont">{today?.emojis}</h1>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        {yesterday && yesterday.title && (
          <Summary
            displayGuessedData={() => titlesToSummaryRow(guessedTitles)}
            yesterdayTitle={yesterday.title}
            result={
              <ResultWithImage<EmojiBookType>
                showResult={showYesterday}
                hideResult={() => setShowYesterday(false)}
                data={yesterday}
              />
            }
            showYesterday={() => setShowYesterday(true)}
            onInfoButtonPressed={() => props.setShowExplanations(true)}
          />
        )}
      </div>
    </>
  );
}
