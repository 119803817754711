import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { ApiRequest, LanguageType } from "../../types/Types";
import "../../App.css";
import { LanguageResult } from "./LanguageResults";
import { isRtlLang } from "rtl-detect";
import { Summary } from "../Summary";
import { titlesToSummaryRow } from "../utils/Utils";

export function LanguageGuesser(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [languages, setLanguages] = useState<string[]>([]);
  const [yesterday, setYesterday] = useState<LanguageType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [win, setWin] = useState(false);
  const [isError, setIsError] = useState(false);
  const [today, setToday] = useState<LanguageType | null>(null);
  const [resultNbGuess, setResultNbGuess] = useState<number>(0);
  const [inputWord, setInputWord] = useState<Option[]>([]);
  const [guessedLanguages, setGuessedLanguages] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showIso, setShowIso] = useState<boolean>(false);
  const [showRegions, setShowRegions] = useState<boolean>(false);

  const revealData = (
    value: boolean,
    setValue: (x: boolean) => void,
    storageKey: string
  ) => {
    if (value) {
      return;
    }

    setValue(true);
    let filmantix = window.localStorage.getItem("languageGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData[storageKey] = true;
      localStorage.setItem("languageGuesser", JSON.stringify(filmantixData));
    }
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(
      process.env.REACT_APP_API_PATH + "/api/languages/today",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & LanguageType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setToday({
            iso: data.iso,
            regions: data.regions,
            sentence: data.sentence,
          });
          let filmantix = window.localStorage.getItem("languageGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const languageDate = new Date(data.today);

            const today = new Date(filmantixData.date);

            if (
              filmantixData?.date &&
              languageDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.revealIso) {
                revealData(showIso, setShowIso, "revealIso");
              }
              if (filmantixData.revealRegions) {
                revealData(showRegions, setShowRegions, "revealRegions");
              }

              if (filmantixData.win) {
                setWin(true);
                setToday(filmantixData.today);
                setResultNbGuess(filmantixData.counter);
              }

              if (filmantixData.guessedLanguages) {
                setGuessedLanguages(filmantixData.guessedLanguages);
              }
            } else {
              const newfilmantixData = {
                date: data.today,
                guessedLanguages: [],
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem(
                "languageGuesser",
                JSON.stringify(newfilmantixData)
              );
              setGuessedLanguages([]);
            }
          } else {
            const filmantixData = {
              date: data.today,
              guessedLanguages: [],
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem(
              "languageGuesser",
              JSON.stringify(filmantixData)
            );
            setGuessedLanguages([]);
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/languages/list",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & { languages: string[] }) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setLanguages(data.languages);
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/languages/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & LanguageType) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setYesterday(null);
        } else {
          setYesterday({
            langue: data.langue,
            iso: data.iso,
            sentence: data.sentence,
            regions: data.regions,
          });
        }
      });
  }, [props, loaded, showIso, showRegions]);

  const sendLanguage = (language: string) => {
    if (!language) {
      return;
    }
    setInputWord([]);

    if (!guessedLanguages.includes(language)) {
      if (!win) {
        setGuessedLanguages((prev) => {
          const data = [language, ...prev];

          let filmantix = window.localStorage.getItem("languageGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);

            filmantixData.guessedLanguages = data;
            localStorage.setItem(
              "languageGuesser",
              JSON.stringify(filmantixData)
            );
          }
          return data;
        });

        const apiUrl = process.env.REACT_APP_API_PATH + "/api/languages/guess";

        // Configuration de la requête
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
          },
          body: JSON.stringify({ language }), // Convertir le tableau en JSON
        };

        fetch(apiUrl, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data: ApiRequest & LanguageType & { counter: number }) => {
            setIsLoading(false);
            if (data.status === "ok") {
              let filmantix = window.localStorage.getItem("languageGuesser");
              if (filmantix) {
                let filmantixData = JSON.parse(filmantix);
                const languageDate = new Date(data.today);
                const today = new Date(filmantixData.today);

                if (
                  filmantixData?.date &&
                  languageDate.getTime() > today.getTime()
                ) {
                  setLoaded(false);
                } else {
                  const todayData = {
                    langue: data.langue,
                    iso: data.iso,
                    sentence: data.sentence,
                    regions: data.regions,
                  };
                  setToday(todayData);
                  setResultNbGuess(data.counter);
                  setWin(true);

                  filmantixData.today = todayData;
                  filmantixData.win = true;
                  filmantixData.counter = data.counter;
                  localStorage.setItem(
                    "languageGuesser",
                    JSON.stringify(filmantixData)
                  );
                }
              }
            }
          });
      }
    }
  };

  const getRevealDiv = (
    title: string,
    show: boolean,
    value: any,
    setValue: (x: boolean) => void,
    storageKey: string
  ) => {
    return (
      <div className="help">
        <b>{title}</b> :{" "}
        {show ? (
          value
        ) : (
          <Button
            className="resultButton helpButton "
            variant="secondary"
            onClick={() => revealData(show, setValue, storageKey)}
          >
            Révéler
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isLoading && <p>Chargement...</p>}
        {isError && <p>Une erreur est survenue !</p>}
        {!isLoading && !isError && (
          <div id="filmantix" className="game emoji">
            {win && (
              <label className="error" style={{ marginBottom: "2%" }}>
                Bravo !! Vous avez trouvé en <i>{guessedLanguages.length}</i>{" "}
                tentatives ! Vous êtes la{" "}
                <b>
                  {resultNbGuess}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir résultat
                </Button>
              </label>
            )}
            {today && (
              <LanguageResult
                showResult={showResults}
                hideResult={() => setShowResults(false)}
                data={today}
                nbOfWords={guessedLanguages.length}
              />
            )}
            <div
              style={{
                width: "95%",
                marginBottom: "2%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {getRevealDiv(
                "Code ISO",
                showIso,
                today?.iso,
                setShowIso,
                "revealIso"
              )}
              {getRevealDiv(
                "Régions du monde",
                showRegions,
                today?.regions.join(" -- "),
                setShowRegions,
                "revealRegions"
              )}
            </div>
            <Form.Group className="emojiForm">
              <Typeahead
                id="basic-typeahead-single"
                className="emojiInput"
                labelKey="bookTitle"
                onChange={setInputWord}
                options={languages}
                placeholder={"Langue..."}
                selected={inputWord}
                disabled={win}
              />

              <button
                className="guessSubmit btn btn-secondary emojiButtonSend"
                disabled={win}
                onClick={() =>
                  sendLanguage(
                    inputWord && inputWord.length > 0
                      ? (inputWord[0] as string)
                      : ""
                  )
                }
              >
                Envoyer
              </button>
            </Form.Group>
            <h1
              id="languageSentence"
              className={isRtlLang(today?.iso ?? "") ? "rtl" : "ltr"}
            >
              {today?.sentence}
            </h1>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        {yesterday && yesterday.langue && (
          <Summary
            displayGuessedData={() => titlesToSummaryRow(guessedLanguages)}
            yesterdayTitle={yesterday.langue}
            result={
              <LanguageResult
                showResult={showYesterday}
                hideResult={() => setShowYesterday(false)}
                data={yesterday}
              />
            }
            showYesterday={() => setShowYesterday(true)}
            onInfoButtonPressed={() => props.setShowExplanations(true)}
          />
        )}
      </div>
    </>
  );
}
