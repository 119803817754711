import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "./App.css";
import { BookGuesser } from "./components/book/BookGuesser";
import { EmojiBook } from "./components/Emojis/EmojiBook";
import { Explanations } from "./components/Explanations";
import { Footer } from "./components/Footer";
import { MovieGuesser } from "./components/movie/MovieGuesser";
import { PosterMovieGuesser } from "./components/movie/poster/PosterMovieGuesser";
import { GameMode, SubMode } from "./types/Enums";
import { EmojiMovie } from "./components/Emojis/EmojiMovie";
import { LanguageGuesser } from "./components/language/LanguageGuesser";

function App() {
  const [gameMode, setGameMode] = useState<GameMode>(GameMode.Movie);
  const [showExplanations, setShowExplanations] = useState(false);
  const [subMode, setSubMode] = useState<SubMode>(SubMode.MovieGuesser);

  const getGameModeBackgroundColor = (tabGameMode: GameMode) => {
    return {
      backgroundColor:
        gameMode === tabGameMode ? "#F8F9FA" : "rgb(175, 175, 175)",
    };
  };

  const getSubModeBackgroundColor = (tabSubMode: SubMode) => {
    return {
      backgroundColor:
        subMode === tabSubMode ? "rgb(100 177 255)" : "rgb(50 102 154)",
    };
  };

  const switchGameMode = (gameMode: GameMode, subMode: SubMode) => {
    setGameMode(gameMode);
    setSubMode(subMode);
  };

  const getSubModeList = () => {
    switch (gameMode) {
      case GameMode.Book:
        return (
          <div id="subModePicker">
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.BookGuesser)}
              onClick={() => setSubMode(SubMode.BookGuesser)}
            >
              📜<b>Synopsis</b>
            </div>
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.EmojiBookGuesser)}
              onClick={() => setSubMode(SubMode.EmojiBookGuesser)}
            >
              📚<b>Emojis</b>
            </div>
          </div>
        );
      case GameMode.Language:
        return (
          <div id="subModePicker">
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.LanguageGuesser)}
              onClick={() => setSubMode(SubMode.LanguageGuesser)}
            >
              🗣️<b>Phrase</b>
            </div>
          </div>
        );
      default:
        return (
          <div id="subModePicker">
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.MovieGuesser)}
              onClick={() => setSubMode(SubMode.MovieGuesser)}
            >
              🎥<b>Film</b>
            </div>
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.PosterMovieGuesser)}
              onClick={() => setSubMode(SubMode.PosterMovieGuesser)}
            >
              🖼️<b>Affiche</b>
            </div>
            <div
              className="subModeTab"
              style={getSubModeBackgroundColor(SubMode.EmojiMovieGuesser)}
              onClick={() => setSubMode(SubMode.EmojiMovieGuesser)}
            >
              🎞️<b>Emojis</b>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="main">
      <div className="title">
        <h1>Filmantix</h1>
      </div>
      <div id="gameMode">
        <div id="gameModePicker">
          <div
            className="gameModeTab"
            style={getGameModeBackgroundColor(GameMode.Movie)}
            onClick={() => switchGameMode(GameMode.Movie, SubMode.MovieGuesser)}
          >
            🎥<b>Films</b>🎥
          </div>
          <div
            className="gameModeTab"
            style={getGameModeBackgroundColor(GameMode.Book)}
            onClick={() => switchGameMode(GameMode.Book, SubMode.BookGuesser)}
          >
            📚<b>Livres</b>📜
          </div>
          <div
            className="gameModeTab"
            style={getGameModeBackgroundColor(GameMode.Language)}
            onClick={() => switchGameMode(GameMode.Language, SubMode.LanguageGuesser)}
          >
            🗣️<b>Langues</b>🌍
          </div>
        </div>

        {getSubModeList()}

        <div id="content">
          <Explanations
            showExplanations={showExplanations}
            hideExplanations={() => setShowExplanations(false)}
            gameMode={subMode}
          />

          <MovieGuesser
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.MovieGuesser}
          />
          <PosterMovieGuesser
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.PosterMovieGuesser}
          />
          <BookGuesser
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.BookGuesser}
          />
          <EmojiBook
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.EmojiBookGuesser}
          />
          <EmojiMovie
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.EmojiMovieGuesser}
          />
          <LanguageGuesser
            setShowExplanations={setShowExplanations}
            display={subMode === SubMode.LanguageGuesser}
          />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
