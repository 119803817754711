import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { FLATRATE_URL } from "../const/flatrateUrl";
import { Flatrate, Item } from "../types/Types";
import { ReactNode } from "react";

export function ResultWithImage<T>(props: {
  data: Item<T>;
  nbOfWords?: number;
  showResult: boolean;
  hideResult: () => void;
}) {
  return (
    <>
      <Modal
        className="gameModal"
        show={props.showResult}
        onHide={props.hideResult}
      >
        <Modal.Header closeButton>
          {props.nbOfWords && (
            <Modal.Title>
              Bravo !! Vous avez trouvé en {props.nbOfWords} tentatives !
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body id="resultBody">
          <Container id="resultContainer">
            <Row>
              <Col sm={7}>
                <Container id="resultLeftContainer">
                  <Row className="flex-grow-1">
                    <Col>
                      <h1>{"title" in props.data && (props.data.title as ReactNode)}</h1>
                      {"emojis" in props.data && <h2 className="emojiFont">{"" + props.data.emojis}</h2>}
                      <h5>{"emojis" in props.data ? (props.data.genres as string[]).join(" - ") : props.data.genres}</h5>
                      <h5>
                        {"date" in props.data && (props.data.date as ReactNode)}{" "}
                        {"runtime" in props.data &&
                          " -- " + props.data.runtime + "min"}
                      </h5>
                      {"companies" in props.data && (
                        <h6>
                          {(props.data.companies as string[]).join(" - ")}
                        </h6>
                      )}
                      {"countries" in props.data && (
                        <h6>
                          {(props.data.countries as string[]).join(" - ")}
                        </h6>
                      )}
                      {"original_language" in props.data && (
                        <h6>
                          {"Langue originale : " + props.data.original_language}
                        </h6>
                      )}
                      {"authors" in props.data && (
                        <h6>{(props.data.authors as string[]).join(" - ")}</h6>
                      )}
                      <div id="synopsis">
                        <p>{"overview" in props.data && (props.data.overview as ReactNode)}</p>
                      </div>
                    </Col>
                  </Row>
                  {"flatrates" in props.data && (
                    <Row>
                      {(props.data.flatrates as Flatrate[]).map((flatrate) => (
                        <Col className="flatrateImage" key={flatrate.name}>
                          <div>
                            <a
                              href={FLATRATE_URL[flatrate.name]}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <Image
                                src={flatrate.logo}
                                alt={flatrate.name}
                                title={flatrate.name}
                              />
                            </a>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Container>
              </Col>
              <Col sm={5} style={{ display: "flex" }}>
                <Image
                  id="resultImg"
                  src={
                    "century" in props.data
                      ? "/covers/" + props.data.image
                      : props.data.image
                  }
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
