import "bootstrap/dist/css/bootstrap.min.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../App.css";
import { BookGuessTypes, GuessTypes } from "../../types/Enums";
import { ApiRequest, Book, GuessBook, GuessList } from "../../types/Types";
import { Summary } from "../Summary";
import { guessedWordsToSummaryRow } from "../utils/Utils";
import { Tips } from "../Tips";
import { Paragraph } from "../Paragraph";
import { ResultWithImage } from "../ResultWithImage";

export function BookGuesser(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [yesterdayTitle, setYesterdayTitle] = useState<string[]>([]);
  const [yesterdayCentury, setYesterdayCentury] = useState<number>(0);
  const [yesterdayDate, setYesterdayDate] = useState<string[]>([]);
  const [yesterdayOverview, setYesterdayOverview] = useState<string[]>([]);
  const [yesterdayImage, setYesterdayImage] = useState<string>("");
  const [yesterdayGenres, setYesterdayGenres] = useState<string[]>([]);
  const [yesterdayAuthors, setYesterdayAuthors] = useState<string[]>([]);
  const [yesterdayOriginalLanguage, setYesterdayOriginalLanguage] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [win, setWin] = useState(false);
  const [unknownWord, setUnknownWord] = useState(false);
  const [isError, setIsError] = useState(false);
  const [image, setImage] = useState<string>("");
  const [date, setDate] = useState<(string | number)[]>([]);
  const [showCentury, setShowCentury] = useState<boolean>(false);
  const [showAuthors, setShowAuthors] = useState<boolean>(false);
  const [showGenres, setShowGenres] = useState<boolean>(false);
  const [showOriginalLanguage, setShowOriginalLanguage] =
    useState<boolean>(false);
  const [century, setCentury] = useState<number>(0);
  const [authors, setAuthors] = useState<string[]>([]);
  const [originalLanguage, setOriginalLanguage] = useState<string>("");
  const [overview, setOverview] = useState<(string | number)[]>([]);
  const [title, setTitle] = useState<(string | number)[]>([]);
  const [genres, setGenres] = useState<string[]>([]);
  const [resultDate, setResultDate] = useState<string[]>([]);
  const [resultOverview, setResultOverview] = useState<string[]>([]);
  const [resultTitle, setResultTitle] = useState<string[]>([]);
  const [resultGenres, setResultGenres] = useState<string[]>([]);
  const [resultCentury, setResultCentury] = useState<number>(0);
  const [resultAuthors, setResultAuthors] = useState<string[]>([]);
  const [resultOriginalLanguage, setResultOriginalLanguage] =
    useState<string>("");
  const [resultNbGuess, setResultNbGuess] = useState<number>(0);
  const [lastInputWord, setLastInputdWord] = useState<string>("Mot");
  const [inputWord, setInputWord] = useState<string>("");
  const [guessedWords, setGuessedWord] = useState<Record<string, GuessBook>>(
    {}
  );
  const [winNbWords, setWinNbWords] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const revealCentury = useCallback(() => {
    if (showCentury) {
      return;
    }

    setShowCentury(true);
    let filmantix = window.localStorage.getItem("bookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealCentury = true;
      localStorage.setItem("bookGuesser", JSON.stringify(filmantixData));
    }
  }, [showCentury]);

  const revealGenres = useCallback(() => {
    if (showGenres) {
      return;
    }

    setShowGenres(true);
    let filmantix = window.localStorage.getItem("bookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealGenres = true;
      localStorage.setItem("bookGuesser", JSON.stringify(filmantixData));
    }
  }, [showGenres]);

  const revealAuthors = useCallback(() => {
    if (showAuthors) {
      return;
    }

    setShowAuthors(true);
    let filmantix = window.localStorage.getItem("bookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealAuthors = true;
      localStorage.setItem("bookGuesser", JSON.stringify(filmantixData));
    }
  }, [showAuthors]);

  const revealOriginalLanguage = useCallback(() => {
    if (showOriginalLanguage) {
      return;
    }

    setShowOriginalLanguage(true);
    let filmantix = window.localStorage.getItem("bookGuesser");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealOriginalLanguage = true;
      localStorage.setItem("bookGuesser", JSON.stringify(filmantixData));
    }
  }, [showOriginalLanguage]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(process.env.REACT_APP_API_PATH + "/api/book/hided", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & Book) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setTitle(data.title);
          setDate(data.date);
          setOverview(data.overview);
          setImage(data.image);
          const splitedGenres = [];
          for (let index = 0; index < data.genres.length; index++) {
            splitedGenres.push(data.genres[index]);
            if (index < data.genres.length - 1) {
              splitedGenres.push(" - ");
            }
          }
          setGenres(splitedGenres);
          setCentury(data.century);
          setAuthors(data.authors);
          setOriginalLanguage(data.original_language);
          let filmantix = window.localStorage.getItem("bookGuesser");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const bookDate = new Date(data.today);

            const today = new Date(filmantixData.today);

            if (
              filmantixData?.today &&
              bookDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.revealCentury) {
                revealCentury();
              }
              if (filmantixData.revealAuthors) {
                revealAuthors();
              }
              if (filmantixData.revealOriginalLanguage) {
                revealOriginalLanguage();
              }
              if (filmantixData.revealGenres) {
                revealGenres();
              }

              if (filmantixData.win) {
                setWin(true);
                setWinNbWords(filmantixData.attempts);
                setResultTitle(filmantixData.title);
                setResultDate(filmantixData.date);
                setResultOverview(filmantixData.overview);
                setImage(filmantixData.image);
                setResultCentury(filmantixData.century);
                setResultAuthors(filmantixData.authors);
                setResultOriginalLanguage(filmantixData.original_language);
                setResultGenres(filmantixData.genres);
                setResultNbGuess(filmantixData.counter);
              }

              if (filmantixData.guessedWords) {
                setGuessedWord(filmantixData.guessedWords);
              }
            } else {
              const filmantixData = {
                today: data.today,
                guessedWords: {},
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem(
                "bookGuesser",
                JSON.stringify(filmantixData)
              );
              setGuessedWord({});
              setShowAuthors(false);
              setShowCentury(false);
              setShowOriginalLanguage(false);
            }
          } else {
            const filmantixData = {
              today: data.today,
              guessedWords: {},
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem("bookGuesser", JSON.stringify(filmantixData));
            setGuessedWord({});
            setShowAuthors(false);
            setShowCentury(false);
            setShowOriginalLanguage(false);
            setGuessedWord({});
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/book/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & Book) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setYesterdayTitle([]);
          setYesterdayDate([]);
          setYesterdayOverview([]);
          setYesterdayImage("");
          setYesterdayAuthors([]);
          setYesterdayCentury(0);
          setYesterdayOriginalLanguage("");
          setYesterdayGenres([]);
        } else {
          setYesterdayTitle(data.title);
          setYesterdayDate(data.date);
          setYesterdayOverview(data.overview);
          setYesterdayImage(data.image);
          setYesterdayAuthors(data.authors);
          setYesterdayCentury(data.century);
          setYesterdayOriginalLanguage(data.original_language);
          const splitedGenres = [];
          for (let index = 0; index < data.genres.length; index++) {
            splitedGenres.push(data.genres[index]);
            if (index < data.genres.length - 1) {
              splitedGenres.push(" - ");
            }
          }
          setYesterdayGenres(splitedGenres);
        }
      });
  }, [
    props,
    loaded,
    revealCentury,
    revealAuthors,
    revealOriginalLanguage,
    revealGenres,
  ]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputWord) {
      return;
    }
    setInputWord("");
    setUnknownWord(false);

    if (guessedWords.hasOwnProperty(inputWord)) {
      setLastInputdWord(inputWord);
    } else {
      const requestOptions: RequestInit = {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
        },
      };

      fetch(
        process.env.REACT_APP_API_PATH +
          "/api/book/similarity?word=" +
          inputWord,
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((data: ApiRequest & GuessList<BookGuessTypes>) => {
          setIsLoading(false);
          if (data.status === "ko") {
            setUnknownWord(true);
          } else {
            let filmantix = window.localStorage.getItem("bookGuesser");
            if (filmantix) {
              let filmantixData = JSON.parse(filmantix);
              const bookDate = new Date(data.today);
              const today = new Date(filmantixData.today);

              if (
                filmantixData?.today &&
                bookDate.getTime() > today.getTime()
              ) {
                setLoaded(false);
              } else {
                setGuessedWord((prevState) => {
                  // Créer une copie de l'état actuel
                  const newState = { ...prevState };
                  // Ajouter la nouvelle valeur
                  newState[inputWord] = {
                    guess: {
                      title: data.title,
                      date: data.date,
                      overview: data.overview,
                    },
                    index: Object.keys(prevState).length,
                  };

                  let filmantix = window.localStorage.getItem("bookGuesser");
                  if (filmantix) {
                    let filmantixData = JSON.parse(filmantix);
                    filmantixData.guessedWords = newState;
                    localStorage.setItem(
                      "bookGuesser",
                      JSON.stringify(filmantixData)
                    );
                  }

                  return newState;
                });
              }
            }
          }

          setLastInputdWord(inputWord);
        });
    }
  };

  const onTitleFilled = () => {
    let filmantix = window.localStorage.getItem("bookGuesser");
    let storageWin = false;
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      if (filmantixData.win) {
        storageWin = true;
      }
    }
    if (!win && !storageWin) {
      const apiUrl = process.env.REACT_APP_API_PATH + "/api/book/today";

      // Configuration de la requête
      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
          // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
        },
        body: JSON.stringify({ guessedWords: Object.keys(guessedWords) }), // Convertir le tableau en JSON
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data: ApiRequest & Book) => {
          setIsLoading(false);
          if (data.status === "ok") {
            let filmantix = window.localStorage.getItem("bookGuesser");
            if (filmantix) {
              let filmantixData = JSON.parse(filmantix);
              const bookDate = new Date(data.today);
              const today = new Date(filmantixData.today);

              if (
                filmantixData?.today &&
                bookDate.getTime() > today.getTime()
              ) {
                setLoaded(false);
              } else {
                let nbWords = Object.keys(guessedWords).length;
                setWinNbWords(nbWords);
                setResultTitle(data.title);
                setResultDate(data.date);
                setResultOverview(data.overview);
                setImage(data.image);
                setResultCentury(data.century);
                setResultAuthors(data.authors);
                setResultOriginalLanguage(data.original_language);
                const splitedGenres = [];
                for (let index = 0; index < data.genres.length; index++) {
                  splitedGenres.push(data.genres[index]);
                  if (index < data.genres.length - 1) {
                    splitedGenres.push(" - ");
                  }
                }
                setResultGenres(splitedGenres);
                setResultNbGuess(data.counter);
                setWin(true);

                filmantixData.attempts = nbWords;
                filmantixData.win = true;
                filmantixData.title = data.title;
                filmantixData.date = data.date;
                filmantixData.overview = data.overview;
                filmantixData.image = data.image;
                filmantixData.century = data.century;
                filmantixData.authors = data.authors;
                filmantixData.original_language = data.original_language;
                filmantixData.genres = splitedGenres;
                filmantixData.counter = data.counter;
                localStorage.setItem(
                  "bookGuesser",
                  JSON.stringify(filmantixData)
                );
              }
            }
          }
        });
    }
  };

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isLoading && <p>Chargement...</p>}
        {isError && <p>Une erreur est survenue !</p>}
        {!isLoading && !isError && (
          <div id="filmantix" className="game">
            {win && (
              <label className="error">
                Bravo !! Vous avez trouvé en <i>{winNbWords}</i> tentatives !
                Vous êtes la{" "}
                <b>
                  {resultNbGuess}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir livre
                </Button>
              </label>
            )}
            <ResultWithImage<Book>
              showResult={showResults}
              hideResult={() => setShowResults(false)}
              data={{
                date: resultDate,
                overview: resultOverview,
                title: resultTitle,
                image: image,
                genres: resultGenres,
                century: resultCentury,
                original_language: resultOriginalLanguage,
                authors: resultAuthors,
                counter: 0,
              }}
              nbOfWords={winNbWords}
            />
            <form className="guessForm" method="post" onSubmit={handleSubmit}>
              <input
                className="guessInput"
                name="guessedWord"
                placeholder={lastInputWord}
                autoComplete="off"
                value={inputWord}
                onChange={(e) => setInputWord(e.target.value)}
              />
              <button className="guessSubmit btn btn-secondary" type="submit">
                Envoyer
              </button>
              {unknownWord ? (
                <label className="error">
                  Le mot <i>{lastInputWord}</i> est inconnu.
                </label>
              ) : (
                <Tips<BookGuessTypes>
                  guessedWords={guessedWords}
                  lastInputWord={lastInputWord}
                  data={{
                    title: title,
                    date: date,
                    overview: overview,
                  }}
                />
              )}
            </form>
            <div className="help">
              <b>Siècle</b> :{" "}
              {showCentury ? (
                century + "ième"
              ) : (
                <Button
                  className="resultButton helpButton  "
                  variant="secondary"
                  onClick={revealCentury}
                >
                  Révéler
                </Button>
              )}
            </div>
            <div className="help">
              <b>Langue originale</b> :{" "}
              {showOriginalLanguage ? (
                originalLanguage
              ) : (
                <Button
                  className="resultButton helpButton "
                  variant="secondary"
                  onClick={revealOriginalLanguage}
                >
                  Révéler
                </Button>
              )}
            </div>
            <div className="help">
              <b>Auteur(e)s</b> :{" "}
              {showAuthors ? (
                authors.join(" -- ")
              ) : (
                <Button
                  className="resultButton helpButton "
                  variant="secondary"
                  onClick={revealAuthors}
                >
                  Révéler
                </Button>
              )}
            </div>
            <div className="help">
              <b>Genres</b> :{" "}
              {showGenres ? (
                genres
              ) : (
                <Button
                  className="resultButton helpButton "
                  variant="secondary"
                  onClick={revealGenres}
                >
                  Révéler
                </Button>
              )}
            </div>
            <h1>
              {
                <Paragraph<BookGuessTypes>
                  onAllFound={onTitleFilled}
                  guessType={GuessTypes.Title}
                  guessedWords={guessedWords}
                  text={title}
                  lastInputWord={lastInputWord}
                />
              }
            </h1>
            <h6>
              {
                <Paragraph<BookGuessTypes>
                  guessType={GuessTypes.Date}
                  guessedWords={guessedWords}
                  text={date}
                  lastInputWord={lastInputWord}
                />
              }
            </h6>
            <div id="synopsis">
              <p>
                {
                  <Paragraph<BookGuessTypes>
                    guessType={GuessTypes.Overview}
                    guessedWords={guessedWords}
                    text={overview}
                    lastInputWord={lastInputWord}
                  />
                }
              </p>
            </div>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        <Summary
          displayGuessedData={() => guessedWordsToSummaryRow(guessedWords)}
          yesterdayTitle={yesterdayTitle}
          result={
            <ResultWithImage<Book>
              showResult={showYesterday}
              hideResult={() => setShowYesterday(false)}
              data={{
                date: yesterdayDate,
                overview: yesterdayOverview,
                title: yesterdayTitle,
                image: yesterdayImage,
                genres: yesterdayGenres,
                century: yesterdayCentury,
                original_language: yesterdayOriginalLanguage,
                authors: yesterdayAuthors,
                counter: 0,
              }}
            />
          }
          showReminder
          showYesterday={() => setShowYesterday(true)}
          onInfoButtonPressed={() => props.setShowExplanations(true)}
        />
      </div>
    </>
  );
}
