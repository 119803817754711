export const titlesToSummaryRow = (titles: string[]) => {
  return titles.map((title, index) => (
    <tr key={index} className="summaryLine">
      <td className="summaryIndex">{titles.length - index}</td>
      <td className="summaryWord">{title}</td>
    </tr>
  ));
};

export const guessedWordsToSummaryRow = (
  guessedWords: Record<
    string,
    {
      index: number;
    }
  >
) => {
  return Object.entries(guessedWords)
    .sort((a, b) => b[1].index - a[1].index)
    .map(([word, guess]) => (
      <tr key={word} className="summaryLine">
        <td className="summaryIndex">{guess.index + 1}</td>
        <td className="summaryWord">{word}</td>
      </tr>
    ));
};
