import { ReactElement, useCallback, useMemo } from "react";
import { BaseGuess } from "../types/Types";
import { GuessTypes } from "../types/Enums";

export function Tips<T extends string>(props: {
  guessedWords: Record<string, BaseGuess<T>>;
  lastInputWord: string;
  data: Record<T, (string | number)[]>;
}) {
  const coloredCircle = (
    color: { r: number; g: number; b: number },
    index: number
  ): ReactElement => {
    const circleStyle = {
      backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
    };

    return (
      <div
        className="circleTip"
        key={"circle-" + index}
        style={circleStyle}
      ></div>
    );
  };

  const computeTips = useCallback(
    (text: (string | number)[], guessType: T) => {
      const tips: { r: number; g: number; b: number }[] = [];
      let index = 0;
      text.forEach((word) => {
        if (typeof word === "number") {
          let tip: { r: number; g: number; b: number } | undefined = undefined;
          let maxSimilarity = 0.0;
          for (const guess in props.guessedWords) {
            const values = props.guessedWords[guess].guess;
            if (values[guessType].hasOwnProperty(index)) {
              const value = values[guessType][index];
              if (typeof value === "number") {
                if (maxSimilarity < value) {
                  maxSimilarity = value;
                  if (props.lastInputWord === guess) {
                    tip = {
                      r: 255,
                      g: Math.round(255 * (1 - value)),
                      b: 0,
                    };
                  } else {
                    tip = undefined;
                  }
                }
              } else {
                if (props.lastInputWord === guess) {
                  tip = {
                    r: 102,
                    g: 288,
                    b: 102,
                  };
                } else {
                  tip = undefined;
                }
                break;
              }
            }
          }

          index++;
          if (tip) {
            tips.push(tip);
          }
        }
      });

      return tips;
    },
    [props.guessedWords, props.lastInputWord]
  );

  const apiResultToTips = useCallback(
    (
      data: Record<T, (string | number)[]>,
      guessedWords: Record<string, BaseGuess<T>>
    ) => {
      const tips: { r: number; g: number; b: number }[] = [];

      Object.values(GuessTypes).forEach((value) => {
        const typedValue: T = value as T;
        if (data.hasOwnProperty(typedValue)) {
          const currentTips = computeTips(data[typedValue], typedValue);
          tips.push(...currentTips);
        }
      })

      tips.sort((a, b) => {
        // Trie d'abord par la propriété 'r'
        if (a.r !== b.r) {
          return a.r - b.r;
        }

        // En cas d'égalité, trie par la propriété 'g'
        return a.g - b.g;
      });

      return (
        <label className="error">
          {tips.map((tip, index) => coloredCircle(tip, index))}
        </label>
      );
    },
    [computeTips]
  );

  const computedTips = useMemo(
    () => apiResultToTips(props.data, props.guessedWords),
    [apiResultToTips, props.data, props.guessedWords]
  );

  return computedTips;
}
