import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../../../App.css";
import placeholderImage from "../../../placeholder.jpg";
import { ApiRequest, Movie } from "../../../types/Types";
import { Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from "react-bootstrap-typeahead";
import { Summary } from "../../Summary";
import { titlesToSummaryRow } from "../../utils/Utils";
import { ResultWithImage } from "../../ResultWithImage";

export function PosterMovieGuesser(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [titles, setTitles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [moviePoster, setMoviePoster] = useState<string>("");
  const [posters, setPosters] = useState<Record<number, string>>({});
  const [difficulty, setDifficulty] = useState<number>(1);
  const [inputWord, setInputWord] = useState<Option[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [guessedTitles, setGuessedTitles] = useState<string[]>([]);
  const [win, setWin] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [yesterday, setYesterday] = useState<Movie | null>(null);
  const [result, setResult] = useState<Movie | null>(null);

  const fetchPoster = async (attempt: number) => {
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    return await fetch(
      process.env.REACT_APP_API_PATH +
        "/api/poster/obfuscate?current_attempt=" +
        attempt,
      requestOptions
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        if (blob) {
          return URL.createObjectURL(blob);
        }
      });
  };

  const getPoster = useCallback(
    (attempt: number, setCurrentPoster: boolean) => {
      fetchPoster(attempt).then((poster) => {
        if (poster) {
          setPosters((prevValue) => {
            prevValue[attempt] = poster;
            return prevValue;
          });
          if (setCurrentPoster) {
            setIsLoading(false);
            setMoviePoster(poster);
          }
        } else {
          setIsError(true);
        }
      });
    },
    []
  );

  const nextPoster = useCallback(() => {
    if (win || difficulty >= 10) {
      return;
    }

    setIsLoading(true);
    setDifficulty((prevValue) => {
      const nextValue = prevValue + 1;
      if (posters[nextValue]) {
        setIsLoading(false);
        setMoviePoster(posters[nextValue]);
      } else {
        getPoster(nextValue, true);
      }

      let filmantix = window.localStorage.getItem("posterGuess");
      if (filmantix) {
        let filmantixData = JSON.parse(filmantix);
        filmantixData.difficulty = nextValue;
        localStorage.setItem("posterGuess", JSON.stringify(filmantixData));
      }
      return nextValue;
    });
  }, [difficulty, getPoster, posters, win]);

  const sendTitle = (title: string) => {
    if (!title) {
      return;
    }
    setInputWord([]);

    if (!guessedTitles.includes(title)) {
      if (!win) {
        setGuessedTitles((prev) => {
          const data = [title, ...prev];

          let filmantix = window.localStorage.getItem("posterGuess");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);

            filmantixData.guessedTitles = data;
            localStorage.setItem("posterGuess", JSON.stringify(filmantixData));
          }
          return data;
        });

        const apiUrl = process.env.REACT_APP_API_PATH + "/api/poster/guess";

        // Configuration de la requête
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
          },
          body: JSON.stringify({ title }), // Convertir le tableau en JSON
        };

        fetch(apiUrl, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data: ApiRequest & Movie & { counter: number }) => {
            setIsLoading(false);
            if (data.status === "ok") {
              setIsLoading(false);
              let filmantix = window.localStorage.getItem("posterGuess");
              if (filmantix) {
                let filmantixData = JSON.parse(filmantix);
                const movieDate = new Date(data.today);
                const today = new Date(filmantixData.today);

                if (
                  filmantixData?.today &&
                  movieDate.getTime() > today.getTime()
                ) {
                  setLoaded(false);
                } else {
                  const splitedGenres = [];
                  for (let index = 0; index < data.genres.length; index++) {
                    splitedGenres.push(data.genres[index]);
                    if (index < data.genres.length - 1) {
                      splitedGenres.push(" - ");
                    }
                  }
                  setResult({
                    date: data.title,
                    overview: data.overview,
                    title: data.title,
                    image: data.image,
                    genres: splitedGenres,
                    companies: data.companies,
                    countries: data.countries,
                    runtime: data.runtime,
                    flatrates: data.flatrates,
                    counter: data.counter,
                  });
                  setWin(true);

                  filmantixData.win = true;
                  filmantixData.title = data.title;
                  filmantixData.date = data.date;
                  filmantixData.overview = data.overview;
                  filmantixData.image = data.image;
                  filmantixData.flatrates = data.flatrates;
                  filmantixData.companies = data.companies;
                  filmantixData.countries = data.countries;
                  filmantixData.genres = splitedGenres;
                  filmantixData.runtime = data.runtime;
                  filmantixData.counter = data.counter;
                  localStorage.setItem(
                    "posterGuess",
                    JSON.stringify(filmantixData)
                  );
                }
              }
            } else {
              nextPoster();
            }
          });
      }
    }
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(process.env.REACT_APP_API_PATH + "/api/poster/today", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & { titles: string[] }) => {
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setTitles(data.titles);
          let diff = 1;
          let filmantix = window.localStorage.getItem("posterGuess");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const posterDate = new Date(data.today);
            const today = new Date(filmantixData.today);

            if (
              filmantixData?.today &&
              posterDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.win) {
                setWin(true);
                setResult({
                  date: filmantixData.title,
                  overview: filmantixData.overview,
                  title: filmantixData.title,
                  image: filmantixData.image,
                  genres: filmantixData.genres,
                  companies: filmantixData.companies,
                  countries: filmantixData.countries,
                  runtime: filmantixData.runtime,
                  flatrates: filmantixData.flatrates,
                  counter: filmantixData.counter,
                });
              }

              if (filmantixData.difficulty) {
                diff = filmantixData.difficulty;
              }

              if (filmantixData.guessedTitles) {
                setGuessedTitles(filmantixData.guessedTitles);
              }
            } else {
              const filmantixData = {
                today: data.today,
                guessedTitles: [],
                difficulty: 1,
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem(
                "posterGuess",
                JSON.stringify(filmantixData)
              );
              setGuessedTitles([]);
            }
          } else {
            const filmantixData = {
              today: data.today,
              guessedTitles: [],
              difficulty: 1,
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem("posterGuess", JSON.stringify(filmantixData));
          }
          setDifficulty(diff);
          setIsLoading(true);
          // 10 is max difficulty
          for (let index = diff; index <= 10; index++) {
            getPoster(index, index === diff);
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/poster/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & Movie) => {
        if (data.status === "ko") {
          setIsError(true);
          setYesterday(null);
        } else {
          const splitedGenres = [];
          for (let index = 0; index < data.genres.length; index++) {
            splitedGenres.push(data.genres[index]);
            if (index < data.genres.length - 1) {
              splitedGenres.push(" - ");
            }
          }
          setYesterday({
            date: data.title,
            overview: data.overview,
            title: data.title,
            image: data.image,
            genres: splitedGenres,
            companies: data.companies,
            countries: data.countries,
            runtime: data.runtime,
            flatrates: data.flatrates,
            counter: 0,
          });
        }
      });
  }, [loaded, getPoster, props]);

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isError && <p>Une erreur est survenue !</p>}
        {!isError && (
          <div id="posterGuesserContainer">
            {win && result && (
              <label className="error">
                Bravo !! Vous avez trouvé en <i>{guessedTitles.length}</i>{" "}
                tentatives ! Vous êtes la{" "}
                <b>
                  {result.counter}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir film
                </Button>
              </label>
            )}
            {result && (
              <ResultWithImage<Movie>
                showResult={showResults}
                hideResult={() => setShowResults(false)}
                data={result}
                nbOfWords={guessedTitles.length}
              />
            )}

            <Form.Group
              className="emojiForm"
              style={{ width: "fit-content", marginBottom: "10px" }}
            >
              <Typeahead
                id="basic-typeahead-single"
                className="emojiInput"
                labelKey="movieTitle"
                onChange={setInputWord}
                options={titles}
                placeholder={"Titre du film..."}
                selected={inputWord}
                disabled={win}
              />

              <button
                className="guessSubmit btn btn-secondary emojiButtonSend"
                disabled={win}
                onClick={() =>
                  sendTitle(
                    inputWord && inputWord.length > 0
                      ? (inputWord[0] as string)
                      : ""
                  )
                }
              >
                Envoyer
              </button>
            </Form.Group>
            <div id="imageContainer">
              {isLoading && (
                <div id="posterLoading">
                  <div className="spinner"></div>
                </div>
              )}
              {
                <img
                  id="poster"
                  src={moviePoster ? moviePoster : placeholderImage}
                  alt="Movie Poster"
                />
              }
            </div>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        {yesterday && (
          <Summary
            displayGuessedData={() => titlesToSummaryRow(guessedTitles)}
            yesterdayTitle={yesterday.title}
            result={
              <ResultWithImage<Movie>
                showResult={showYesterday}
                hideResult={() => setShowYesterday(false)}
                data={yesterday}
              />
            }
            showYesterday={() => setShowYesterday(true)}
            onInfoButtonPressed={() => props.setShowExplanations(true)}
          />
        )}
      </div>
    </>
  );
}
