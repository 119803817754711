import { Col, Container, Modal, Row } from "react-bootstrap";
import { LanguageType } from "../../types/Types";
import { isRtlLang } from "rtl-detect";

export function LanguageResult(props: {
  data: LanguageType;
  nbOfWords?: number;
  showResult: boolean;
  hideResult: () => void;
}) {
  return (
    <>
      <Modal
        className="gameModal"
        show={props.showResult}
        onHide={props.hideResult}
      >
        <Modal.Header closeButton>
          {props.nbOfWords && (
            <Modal.Title>
              Bravo !! Vous avez trouvé en {props.nbOfWords} tentatives !
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body id="resultBody">
          <Container id="resultContainer">
            <Row>
              <Col>
                <Container id="resultLeftContainer">
                  <Row className="flex-grow-1">
                    <Col>
                      <h1>{props.data.langue}</h1>
                      <h4>Code ISO : {props.data.iso}</h4>
                      <h4>Parlé principalement dans les régions : {props.data.regions.join(" -- ")}</h4>
                      <div id="synopsis" style={{ marginTop: '3%'}}>
                        <h6 className={isRtlLang(props.data.iso) ? 'rtl' : 'ltr'}>{props.data.sentence}</h6>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
