import { useEffect, useState } from "react";
import { BaseGuess } from "../types/Types";

type Word = {
  originalWord: number;
  content: string;
  found: boolean;
  style?: React.CSSProperties;
};

export function Paragraph<T extends string>(props: {
  onAllFound?: () => void;
  guessType: T;
  guessedWords: Record<string, BaseGuess<T>>;
  text: (string | number)[];
  lastInputWord: string;
}) {
  const [value, setValue] = useState<Word[]>([]);
  const [saveValue, setSaveValue] = useState<Word[]>([]);

  const handleSpanClick = (index: number) => {
    const word = saveValue[index];
    setValue((prevValue) => {
      const newValue = [...prevValue];

      let content = word.originalWord + "";

      let left = true;
      while (content.length < word.content.length) {
        if (left) {
          content = "\u00A0" + content;
        } else {
          content = content + "\u00A0";
        }

        left = !left;
      }

      newValue[index] = {
        ...newValue[index],
        content: content,
        style: {
          ...word.style,
          color: "cyan",
        },
      };
      return newValue;
    });

    // Rétablissez le contenu initial après 5 secondes
    setTimeout(() => {
      setValue((prevValue) => {
        const newValue = [...prevValue];
        newValue[index] = word;
        return newValue;
      });
    }, 3000);
  };

  const createSpan = (word: Word, index: number) => (
    <span
      key={index}
      className="w"
      style={word.style}
      onClick={word.found ? undefined : () => handleSpanClick(index)}
    >
      {word.content}
    </span>
  );

  useEffect(() => {
    let allFound = true;
    let index = 0;
    const html: Word[] = props.text.map((word) => {
      if (typeof word !== "number") {
        return {
          originalWord: 0,
          content: word,
          found: true,
        };
      }

      let found = false;
      let maxSimilarity = 0.0;
      let displayedWord: Word = {
        originalWord: word,
        content: "\u00A0".repeat(word),
        found: false,
      };

      for (const guess in props.guessedWords) {
        const values = props.guessedWords[guess].guess;
        if (values[props.guessType]?.hasOwnProperty(index)) {
          const value = values[props.guessType][index];
          if (typeof value === "number") {
            if (maxSimilarity < value) {
              maxSimilarity = value;

              const spacesToAdd = word - guess.length;
              let newGuess = "\u00A0" + guess + "\u00A0";

              if (spacesToAdd > 0) {
                const spaces = "\u00A0".repeat(Math.floor(spacesToAdd / 2));
                newGuess = spaces + newGuess + spaces;
              }

              displayedWord = {
                originalWord: word,
                content: newGuess,
                found: false,
                style: {
                  backgroundColor: "rgb(51, 51, 51)",
                  boxShadow: "rgb(184, 185, 186) 1px 1px 1px",
                  minWidth: "43px",
                  color:
                    props.lastInputWord === guess
                      ? "rgb(255, " + Math.round(255 * (1 - value)) + ", 0)"
                      : "rgb(" +
                        Math.max(120, Math.round(255 * value)) +
                        "," +
                        Math.max(120, Math.round(255 * value)) +
                        "," +
                        Math.max(120, Math.round(255 * value)) +
                        ")",
                },
              };
            }
          } else {
            found = true;
            displayedWord = {
              originalWord: word,
              content: value,
              found: true,
              style: {
                color: "rgb(32, 33, 34)",
                display: "inline",
                fontFamily: "inherit",
                fontSize: "inherit",
                lineHeight: "inherit",
                backgroundColor:
                  props.lastInputWord === guess
                    ? "rgb(102, 238, 102)"
                    : "rgb(248, 249, 250)",
                boxShadow: "none",
              },
            };
            break;
          }
        }
      }

      if (allFound) {
        allFound = found;
      }

      index++;
      return displayedWord;
    });

    if (allFound && props.onAllFound) {
      props.onAllFound();
    }

    setValue(html);
    setSaveValue(html);
  }, [props]);

  return (
    <>
      {value.map((word, index) => {
        if (word.found && !word.style) {
          return word.content;
        }

        return createSpan(word, index);
      })}
    </>
  );
}
