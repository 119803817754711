export enum GameMode {
  Movie,
  Book,
  Language
}

export enum SubMode {
  MovieGuesser,
  PosterMovieGuesser,
  BookGuesser,
  EmojiBookGuesser,
  EmojiMovieGuesser,
  LanguageGuesser
}

type ExcludeEnumValues<T extends string, ExcludeValues extends T> = {
  [K in T]: K extends ExcludeValues ? never : K;
}[T];

export enum GuessTypes {
  Date = "date",
  Overview = "overview",
  Title = "title",
  Genres = "genres",
  Runtime = "runtime",
}

export type BookGuessTypes = ExcludeEnumValues<GuessTypes, GuessTypes.Genres | GuessTypes.Runtime>