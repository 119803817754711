import "bootstrap/dist/css/bootstrap.min.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../App.css";
import { GuessTypes } from "../../types/Enums";
import { ApiRequest, Flatrate, GuessList, GuessMovie, Movie } from "../../types/Types";
import { Paragraph } from "../Paragraph";
import { Summary } from "../Summary";
import { guessedWordsToSummaryRow } from "../utils/Utils";
import { Tips } from "../Tips";
import { ResultWithImage } from "../ResultWithImage";

export function MovieGuesser(props: {
  display: boolean;
  setShowExplanations: (value: boolean) => void;
}) {
  const [showYesterday, setShowYesterday] = useState(false);
  const [yesterdayTitle, setYesterdayTitle] = useState<string[]>([]);
  const [yesterdayFlatrates, setYesterdayFlatrates] = useState<Flatrate[]>([]);
  const [yesterdayDate, setYesterdayDate] = useState<string[]>([]);
  const [yesterdayOverview, setYesterdayOverview] = useState<string[]>([]);
  const [yesterdayImage, setYesterdayImage] = useState<string>("");
  const [yesterdayGenres, setYesterdayGenres] = useState<string[]>([]);
  const [yesterdayCompanies, setYesterdayCompanies] = useState<string[]>([]);
  const [yesterdayCountries, setYesterdayCountries] = useState<string[]>([]);
  const [yesterdayRuntime, setYesterdayRuntime] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [win, setWin] = useState(false);
  const [unknownWord, setUnknownWord] = useState(false);
  const [isError, setIsError] = useState(false);
  const [image, setImage] = useState<string>("");
  const [flatrates, setFlatrates] = useState<Flatrate[]>([]);
  const [date, setDate] = useState<(string | number)[]>([]);
  const [showCompanies, setShowCompanies] = useState<boolean>(false);
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [companies, setCompanies] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [runtime, setRuntime] = useState<(string | number)[]>([]);
  const [overview, setOverview] = useState<(string | number)[]>([]);
  const [title, setTitle] = useState<(string | number)[]>([]);
  const [genres, setGenres] = useState<(string | number)[]>([]);
  const [resultDate, setResultDate] = useState<string[]>([]);
  const [resultOverview, setResultOverview] = useState<string[]>([]);
  const [resultTitle, setResultTitle] = useState<string[]>([]);
  const [resultGenres, setResultGenres] = useState<string[]>([]);
  const [resultCompanies, setResultCompanies] = useState<string[]>([]);
  const [resultCountries, setResultCountries] = useState<string[]>([]);
  const [resultRuntime, setResultRuntime] = useState<string[]>([]);
  const [resultNbGuess, setResultNbGuess] = useState<number>(0);
  const [lastInputWord, setLastInputdWord] = useState<string>("Mot");
  const [inputWord, setInputWord] = useState<string>("");
  const [guessedWords, setGuessedWord] = useState<Record<string, GuessMovie>>({});
  const [winNbWords, setWinNbWords] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const revealCountries = useCallback(() => {
    if (showCountries) {
      return;
    }

    setShowCountries(true);
    let filmantix = window.localStorage.getItem("filmantix");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealCountries = true;
      localStorage.setItem("filmantix", JSON.stringify(filmantixData));
    }
  }, [showCountries]);

  const revealCompanies = useCallback(() => {
    if (showCompanies) {
      return;
    }

    setShowCompanies(true);
    let filmantix = window.localStorage.getItem("filmantix");
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      filmantixData.revealCompanies = true;
      localStorage.setItem("filmantix", JSON.stringify(filmantixData));
    }
  }, [showCompanies]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    setLoaded(true);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };

    fetch(process.env.REACT_APP_API_PATH + "/api/movie/hided", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & Movie) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setTitle(data.title);
          setDate(data.date);
          setOverview(data.overview);
          setImage(data.image);
          const splitedGenres = [];
          for (let index = 0; index < data.genres.length; index++) {
            splitedGenres.push(data.genres[index]);
            if (index < data.genres.length - 1) {
              splitedGenres.push(" - ");
            }
          }
          setGenres(splitedGenres);
          setCompanies(data.companies);
          setCountries(data.countries);
          setRuntime(data.runtime);
          let filmantix = window.localStorage.getItem("filmantix");
          if (filmantix) {
            let filmantixData = JSON.parse(filmantix);
            const movieDate = new Date(data.today);

            const today = new Date(filmantixData.today);

            if (
              filmantixData?.today &&
              movieDate.getTime() <= today.getTime() &&
              !isNaN(filmantixData?.version) &&
              filmantixData.version >= (process.env.REACT_APP_VERSION ?? 0)
            ) {
              if (filmantixData.revealCountries) {
                revealCountries();
              }
              if (filmantixData.revealCompanies) {
                revealCompanies();
              }

              if (filmantixData.win) {
                setWin(true);
                setWinNbWords(filmantixData.attempts);
                setResultTitle(filmantixData.title);
                setResultDate(filmantixData.date);
                setResultOverview(filmantixData.overview);
                setImage(filmantixData.image);
                setFlatrates(filmantixData.flatrates);
                setResultCompanies(filmantixData.companies);
                setResultCountries(filmantixData.countries);
                setResultGenres(filmantixData.genres);
                setResultRuntime(filmantixData.runtime);
                setResultNbGuess(filmantixData.counter);
              }

              if (filmantixData.guessedWords) {
                setGuessedWord(filmantixData.guessedWords);
              }
            } else {
              const filmantixData = {
                today: data.today,
                guessedWords: {},
                version: process.env.REACT_APP_VERSION,
              };
              localStorage.setItem("filmantix", JSON.stringify(filmantixData));
              setGuessedWord({});
              setShowCompanies(false);
              setShowCountries(false);
            }
          } else {
            props.setShowExplanations(true);
            const filmantixData = {
              today: data.today,
              guessedWords: {},
              version: process.env.REACT_APP_VERSION,
            };
            localStorage.setItem("filmantix", JSON.stringify(filmantixData));
            setGuessedWord({});
            setShowCompanies(false);
            setShowCountries(false);
          }
        }
      });

    fetch(
      process.env.REACT_APP_API_PATH + "/api/movie/yesterday",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data: ApiRequest & Movie) => {
        setIsLoading(false);
        if (data.status === "ko") {
          setIsError(true);
        } else {
          setYesterdayTitle(data.title);
          setYesterdayDate(data.date);
          setYesterdayOverview(data.overview);
          setYesterdayImage(data.image);
          setYesterdayFlatrates(data.flatrates);
          setYesterdayCompanies(data.companies);
          setYesterdayCountries(data.countries);
          const splitedGenres = [];
          for (let index = 0; index < data.genres.length; index++) {
            splitedGenres.push(data.genres[index]);
            if (index < data.genres.length - 1) {
              splitedGenres.push(" - ");
            }
          }
          setYesterdayGenres(splitedGenres);
          setYesterdayRuntime(data.runtime);
        }
      });
  }, [props, loaded, revealCompanies, revealCountries]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputWord) {
      return;
    }
    setInputWord("");
    setUnknownWord(false);

    if (guessedWords.hasOwnProperty(inputWord)) {
      setLastInputdWord(inputWord);
    } else {
      const requestOptions: RequestInit = {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
        },
      };

      fetch(
        process.env.REACT_APP_API_PATH +
          "/api/movie/similarity?word=" +
          inputWord,
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((data: ApiRequest & GuessList<GuessTypes>) => {
          setIsLoading(false);
          if (data.status === "ko") {
            setUnknownWord(true);
          } else {
            let filmantix = window.localStorage.getItem("filmantix");
            if (filmantix) {
              let filmantixData = JSON.parse(filmantix);
              const movieDate = new Date(data.today);
              const today = new Date(filmantixData.today);

              if (
                filmantixData?.today &&
                movieDate.getTime() > today.getTime()
              ) {
                setLoaded(false);
              } else {
                setGuessedWord((prevState) => {
                  // Créer une copie de l'état actuel
                  const newState = { ...prevState };
                  // Ajouter la nouvelle valeur
                  newState[inputWord] = {
                    guess: {
                      title: data.title,
                      date: data.date,
                      overview: data.overview,
                      genres: data.genres,
                      runtime: data.runtime,
                    },
                    index: Object.keys(prevState).length,
                  };

                  let filmantix = window.localStorage.getItem("filmantix");
                  if (filmantix) {
                    let filmantixData = JSON.parse(filmantix);
                    filmantixData.guessedWords = newState;
                    localStorage.setItem(
                      "filmantix",
                      JSON.stringify(filmantixData)
                    );
                  }

                  return newState;
                });
              }
            }
          }

          setLastInputdWord(inputWord);
        });
    }
  };

  const onTitleFilled = () => {
    let filmantix = window.localStorage.getItem("filmantix");
    let storageWin = false;
    if (filmantix) {
      let filmantixData = JSON.parse(filmantix);
      if (filmantixData.win) {
        storageWin = true;
      }
    }
    if (!win && !storageWin) {
      const apiUrl = process.env.REACT_APP_API_PATH + "/api/movie/today";

      // Configuration de la requête
      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
          // Vous pouvez également ajouter d'autres en-têtes ici si nécessaire
        },
        body: JSON.stringify({ guessedWords: Object.keys(guessedWords) }), // Convertir le tableau en JSON
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data: ApiRequest & Movie) => {
          setIsLoading(false);
          if (data.status === "ok") {
            let filmantix = window.localStorage.getItem("filmantix");
            if (filmantix) {
              let filmantixData = JSON.parse(filmantix);
              const movieDate = new Date(data.today);
              const today = new Date(filmantixData.today);

              if (
                filmantixData?.today &&
                movieDate.getTime() > today.getTime()
              ) {
                setLoaded(false);
              } else {
                let nbWords = Object.keys(guessedWords).length;
                setWinNbWords(nbWords);
                setResultTitle(data.title);
                setResultDate(data.date);
                setResultOverview(data.overview);
                setImage(data.image);
                setFlatrates(data.flatrates);
                setResultCompanies(data.companies);
                setResultCountries(data.countries);
                const splitedGenres = [];
                for (let index = 0; index < data.genres.length; index++) {
                  splitedGenres.push(data.genres[index]);
                  if (index < data.genres.length - 1) {
                    splitedGenres.push(" - ");
                  }
                }
                setResultGenres(splitedGenres);
                setResultRuntime(data.runtime);
                setResultNbGuess(data.counter);
                setWin(true);

                filmantixData.attempts = nbWords;
                filmantixData.win = true;
                filmantixData.title = data.title;
                filmantixData.date = data.date;
                filmantixData.overview = data.overview;
                filmantixData.image = data.image;
                filmantixData.flatrates = data.flatrates;
                filmantixData.companies = data.companies;
                filmantixData.countries = data.countries;
                filmantixData.genres = splitedGenres;
                filmantixData.runtime = data.runtime;
                filmantixData.counter = data.counter;
                localStorage.setItem(
                  "filmantix",
                  JSON.stringify(filmantixData)
                );
              }
            }
          }
        });
    }
  };

  return (
    <>
      <div
        className="movie"
        style={{ display: props.display ? "block" : "none" }}
      >
        {isLoading && <p>Chargement...</p>}
        {isError && <p>Une erreur est survenue !</p>}
        {!isLoading && !isError && (
          <div id="filmantix" className="game">
            {win && (
              <label className="error">
                Bravo !! Vous avez trouvé en <i>{winNbWords}</i> tentatives !
                Vous êtes la{" "}
                <b>
                  {resultNbGuess}
                  <sup>e</sup>
                </b>{" "}
                personne à trouver !
                <Button
                  className="resultButton"
                  variant="secondary"
                  onClick={() => setShowResults(win)}
                >
                  Voir film
                </Button>
              </label>
            )}
            <ResultWithImage<Movie>
              showResult={showResults}
              hideResult={() => setShowResults(false)}
              nbOfWords={winNbWords}
              data={{
                date: resultDate,
                overview: resultOverview,
                title: resultTitle,
                image: image,
                genres: resultGenres,
                companies: resultCompanies,
                countries: resultCountries,
                runtime: resultRuntime,
                flatrates: flatrates,
                counter: 0
              }}
            />
            <form className="guessForm" method="post" onSubmit={handleSubmit}>
              <input
                className="guessInput"
                name="guessedWord"
                placeholder={lastInputWord}
                autoComplete="off"
                value={inputWord}
                onChange={(e) => setInputWord(e.target.value)}
              />
              <button className="guessSubmit btn btn-secondary" type="submit">
                Envoyer
              </button>
              {unknownWord ? (
                <label className="error">
                  Le mot <i>{lastInputWord}</i> est inconnu.
                </label>
              ) : (
                <Tips<GuessTypes>
                  guessedWords={guessedWords}
                  lastInputWord={lastInputWord}
                  data={{
                    title: title,
                    date: date,
                    overview: overview,
                    genres: genres,
                    runtime: runtime,
                  }}
                />
              )}
            </form>
            <div className="help">
              <b>Pays de production</b> :{" "}
              {showCountries ? (
                countries.join(" -- ")
              ) : (
                <Button
                  className="resultButton helpButton  "
                  variant="secondary"
                  onClick={revealCountries}
                >
                  Révéler
                </Button>
              )}
            </div>
            <div className="help">
              <b>Compagnies de production</b> :{" "}
              {showCompanies ? (
                companies.join(" -- ")
              ) : (
                <Button
                  className="resultButton helpButton "
                  variant="secondary"
                  onClick={revealCompanies}
                >
                  Révéler
                </Button>
              )}
            </div>
            <h1>
              {
                <Paragraph<GuessTypes>
                  onAllFound={onTitleFilled}
                  guessType={GuessTypes.Title}
                  guessedWords={guessedWords}
                  text={title}
                  lastInputWord={lastInputWord}
                />
              }
            </h1>
            <h5>
              {
                <Paragraph<GuessTypes>
                  guessType={GuessTypes.Genres}
                  guessedWords={guessedWords}
                  text={genres}
                  lastInputWord={lastInputWord}
                />
              }
            </h5>
            <h6>
              {
                <Paragraph<GuessTypes>
                  guessType={GuessTypes.Date}
                  guessedWords={guessedWords}
                  text={date}
                  lastInputWord={lastInputWord}
                />
              }
              <span id="genresSeparator">---</span>
              {
                <Paragraph<GuessTypes>
                  guessType={GuessTypes.Runtime}
                  guessedWords={guessedWords}
                  text={runtime}
                  lastInputWord={lastInputWord}
                />
              }
              min
            </h6>
            <div id="synopsis">
              <p>
                {
                  <Paragraph<GuessTypes>
                    guessType={GuessTypes.Overview}
                    guessedWords={guessedWords}
                    text={overview}
                    lastInputWord={lastInputWord}
                  />
                }
              </p>
            </div>
          </div>
        )}
      </div>

      <div
        className="summary"
        style={{ display: props.display ? "block" : "none" }}
      >
        <Summary
          displayGuessedData={() => guessedWordsToSummaryRow(guessedWords)}
          yesterdayTitle={yesterdayTitle}
          result={
            <ResultWithImage<Movie>
              showResult={showYesterday}
              hideResult={() => setShowYesterday(false)}
              data={{
                date: yesterdayDate,
                overview: yesterdayOverview,
                title: yesterdayTitle,
                image: yesterdayImage,
                genres: yesterdayGenres,
                companies: yesterdayCompanies,
                countries: yesterdayCountries,
                runtime: yesterdayRuntime,
                flatrates: yesterdayFlatrates,
                counter: 0
              }}
            />
          }
          showReminder
          showYesterday={() => setShowYesterday(true)}
          onInfoButtonPressed={() => props.setShowExplanations(true)}
        />
      </div>
    </>
  );
}
