export function Reminder() {
  return (
    <span className="reminder">
      <b>Rappel :</b>
      <br />
      Mot trouvé : {"\u00A0"}
      <div
        className="circleTip"
        key={"circle-green-1"}
        style={{
          backgroundColor: `rgb(102, 288, 102)`,
        }}
      ></div>
      <br />
      Mot similaire (du plus ou moins proche) :
      <br />
      <div
        className="circleTip"
        key={"circle-red-2"}
        style={{
          backgroundColor: `rgb(255, 0, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-3"}
        style={{
          backgroundColor: `rgb(255, 26, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-4"}
        style={{
          backgroundColor: `rgb(255, 51, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-5"}
        style={{
          backgroundColor: `rgb(255, 77, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-6"}
        style={{
          backgroundColor: `rgb(255, 102, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-7"}
        style={{
          backgroundColor: `rgb(255, 128, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-8"}
        style={{
          backgroundColor: `rgb(255, 153, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-9"}
        style={{
          backgroundColor: `rgb(255, 179, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-10"}
        style={{
          backgroundColor: `rgb(255, 204, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-11"}
        style={{
          backgroundColor: `rgb(255, 230, 0)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-red-12"}
        style={{
          backgroundColor: `rgb(255, 255, 0)`,
        }}
      ></div>
      <br />
      puis :
      <br />
      <div
        className="circleTip"
        key={"circle-grey-7"}
        style={{
          backgroundColor: `rgb(255, 255, 255)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-grey-6"}
        style={{
          backgroundColor: `rgb(230, 230, 230)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-grey-5"}
        style={{
          backgroundColor: `rgb(204, 204, 204)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-grey  -4"}
        style={{
          backgroundColor: `rgb(179, 179, 179)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-grey-3"}
        style={{
          backgroundColor: `rgb(128, 128, 128)`,
        }}
      ></div>
      <div
        className="circleTip"
        key={"circle-grey-2"}
        style={{
          backgroundColor: `rgb(120, 120, 120)`,
        }}
      ></div>
    </span>
  );
}
