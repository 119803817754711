export const FLATRATE_URL: Record<string, string> = {
  FILMO: "https://www.filmotv.fr",
  "FilmBox+": "https://www.filmbox.com",
  "MUBI Amazon Channel": "https://www.primevideo.com/offers/?benefitId=mubi",
  "Action Max Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=actionmaxfr",
  "OCS Amazon Channel ": "https://www.primevideo.com/offers/?benefitId=ocsfr",
  "Amazon Prime Video": "https://www.primevideo.com",
  "France TV": "https://www.france.tv",
  Netflix: "https://www.netflix.com",
  "MGM Amazon Channel": "https://www.primevideo.com/offers/?benefitId=mgmfr",
  "Netflix basic with Ads": "https://www.netflix.com",
  "Pass Warner Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=warnerpassfr",
  "Canal+": "https://www.canalplus.com",
  "Molotov TV": "https://www.molotov.tv",
  "OCS Go": "https://www.ocs.fr",
  "Shadowz Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=shadowzfr",
  Shadowz: "https://www.shadowz.fr",
  "Filmo Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=filmotvfr",
  "Paramount Plus": "https://www.paramountplus.com/",
  "Paramount+ Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=paramountplusfr",
  LaCinetek: "https://www.lacinetek.com/",
  "Insomnia Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=insomniafr",
  "Universcine Amazon Channel":
    "https://www.primevideo.com/offers/?benefitId=universcinefr",
  "Disney Plus": "https://www.disneyplus.com",
  "Paramount Plus Apple TV Channel ": "https://tv.apple.com",
  Cultpix: "https://www.cultpix.com",
  MUBI: "https://mubi.com",
};
