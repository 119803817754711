import { ReactNode } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Reminder } from "./Reminder";
import { getDayOfYear } from "./utils/DateUtils";

export function Summary(props: {
  displayGuessedData: () => any;
  yesterdayTitle: string | string[];
  result: ReactNode;
  showReminder?: boolean;
  showYesterday: () => void;
  onInfoButtonPressed: () => void;
}) {
  return (
    <div id="summaryCard">
      {props.result}
      <div id="summaryBody">
        <span className="infoButtonSpan">
          <Button
            id="infoButton"
            variant="secondary"
            className="rounded-circle"
            onClick={props.onInfoButtonPressed}
          >
            i
          </Button>
        </span>
        {props.showReminder && <Reminder />}
        <Card.Title style={{ marginTop: props.showReminder ? "" : "10%" }}>
          Jour n°{getDayOfYear()}
        </Card.Title>
        <div
          style={{
            height: props.showReminder ? "55%" : "70%",
            marginBottom: "0.5em",
          }}
        >
          <Table responsive>
            <thead>
              <tr>
                <th className="summaryHead summaryIndex">N°</th>
                <th className="summaryHead summaryWord">Mot</th>
              </tr>
            </thead>
            <tbody>{props.displayGuessedData()}</tbody>
          </Table>
        </div>
        {props.yesterdayTitle && (
          <div id="yesterday">
            <div id="yesterdayTitle">
              Le résultat d'hier était : <br />
            </div>

            <b id="yesterdayButton" onClick={() => props.showYesterday()}>
              {props.yesterdayTitle}
            </b>
          </div>
        )}
      </div>
    </div>
  );
}
